import { IPlatformAPI } from '@wix/native-components-infra/dist/src/types/types';
import { getExperimentsFromCache } from './getExperimentsFromCache';
import { conductAllByScope } from './getExperimentsByScope';
import { EXPERIMENTS_SCOPE } from '../app-configs';
import { ExperimentsBag } from '@wix/wix-experiments';

export class ExperimentsProvider {
  static experimentsBag: ExperimentsBag = {};

  static async setExperiments(
    platformApis: IPlatformAPI,
    metaSiteId: string,
    isSSR: boolean,
  ) {
    let experimentsBag = getExperimentsFromCache(platformApis);
    if (!experimentsBag) {
      try {
        // We need the full url due to SSR
        // and "https" seems to be the most common way to fetch the specs
        const baseUrl = isSSR ? 'https://www.wix.com' : undefined;

        experimentsBag = await conductAllByScope(
          EXPERIMENTS_SCOPE,
          metaSiteId,
          baseUrl,
        );
        platformApis.storage.session.setItem(
          EXPERIMENTS_SCOPE,
          JSON.stringify(experimentsBag),
        );
      } catch (e) {
        console.log('Error in initAppForPage');
      }
    }
    ExperimentsProvider.experimentsBag = experimentsBag;
  }
}
